/* global rp$, window */
'use strict';

const get = require('lodash/get');
const utils = require('public/js/helpers/utils');


var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	const FORWARD_SLASH = '/';

	/****************************************************************************
	 * Load Javascript for Login/Register Module - handles redirect query strings
	 * --------------------------------------------------------------------------
	 * @section login_register_redirect_js
	 ****************************************************************************/
	(function LoginRegisterRedirectHandler() {
		let $loginLink,
			$registerLink;
		const cookie_name_ = 'USER_REGISTRATION_'; 
		
		$onReady({
			rp$, window,
			label: 'login_register_redirect',
			fn: function () {
					try {
						$loginLink = rp$('.login-redirect-link');
						$registerLink = rp$('.register-redirect-link');

						if(window.tracking.google.use_gtag_admind_analytics){
							let cookie_value = JSON.parse(decodeURIComponent(utils.getCookie(cookie_name_)));
							if(cookie_value.email){
								utils.hashSensitiveInformation(cookie_value.email).then(function (hashedValue) {
									window.rp_app.track.track_ec_general('start_account_sign_up', {
										email:hashedValue,
									});
									utils.deleteCookie(cookie_name_);
								});
							}
						}
					} catch (e) {
						console.error('Error in login_register_redirect_js:', e);
                        return;
					}

					const $baseLoginHref = get($loginLink, '[0].href');
					const $baseRegisterHref = get($registerLink, '[0].href');
					if ($baseLoginHref && $baseRegisterHref) {
						const currentUrlPath = get(window, 'location.pathname');
						const currentUrlQueryString = get(window, 'location.search');

						// Append redirect query string [Currently FCA Only]
						const redirectQueryString = `?tg=REVPCDRAPOMREVP&p=${encodeURIComponent(`${currentUrlPath}${currentUrlQueryString}`)}`;

						// Login
						let queryStringPrefix = $baseLoginHref.endsWith(FORWARD_SLASH) ? '' : FORWARD_SLASH;
						const $newLoginHref = `${$baseLoginHref}${queryStringPrefix}${redirectQueryString}`;

						// Register
						queryStringPrefix = $baseRegisterHref.endsWith(FORWARD_SLASH) ? '' : FORWARD_SLASH;
						const $newRegisterHref = `${$baseRegisterHref}${queryStringPrefix}${redirectQueryString}`;

						// Set new hrefs
						$loginLink[0].href = $newLoginHref;
						$registerLink[0].href = $newRegisterHref;
					}
			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}